<template>
  <div class="invite-container">
    <the-header :visibleOnInvite="isAuthenticatedUser" whiteColor>
      <template v-slot:right>
        <div class="header__right-navigation" v-if="isAuthenticatedUser">
          <!-- <div class="weather-icon">
              <i
                class="icon navigation-icon icon-brightness weather-icon__icon"
              ></i>
              <p class="weather-icon__para">91.4°Fs</p>
            </div> -->
          <!-- <i
              class="icon navigation-icon icon-bookmark notification__icon"
            ></i> -->

          <!-- <router-link to="/workspace/write" class="btn btn-white"
              >Post Article</router-link
            > -->
          <a-tooltip class="link-tooltip">
            <template #title>Alerts</template>
            <div v-if="showOnSubscription" @click="showNotifications()" class="notification">
              <img src="@/assets/icons/notifications-icon.svg" 
                alt="icon"
                class="navigation-icon bell-icon"
              />
              <span
                v-if="isNotifications"
                class="notifications-alert"
              ></span>
            </div>
          </a-tooltip>
          <!-- <a-tooltip class="link-tooltip">
            <template #title>Portfolio</template>
            <img src="@/assets/icons/portfolio-white.svg" alt="icon"
              @click="redirectToTabs('portfolio')"
              class="navigation-icon"
            />
          </a-tooltip> -->
          <a-tooltip class="link-tooltip">
            <template #title>Network</template>
            <img src="@/assets/icons/diversity-icon.png" alt="icon"
              @click="redirectToTabs('network')"
              class="navigation-icon"
            />
          </a-tooltip>
          <a-tooltip class="link-tooltip profile-dropdown-tootip">
            <template #title>Other's</template>
            <a-dropdown :trigger="['click']" placement="bottomRight">
              <a class="ant-dropdown-link">
                <img 
                  :src="displayPicture" 
                  alt="user"
                  class="navigation-icon icon-account"
                />
              </a>
              <template #overlay>
                <accounts-dropdown></accounts-dropdown>
              </template>
            </a-dropdown>
          </a-tooltip>
        </div>
        <div class="header__right-navigation" v-if="!isAuthenticatedUser">
          <a-button @click="showLoginModal" class="login-btn">Login</a-button>
        </div>
      </template>
    </the-header>
    <div v-if="isAuthenticatedUser" class="invite">
      <div class="invite__icon">
        <img src="@/assets/images/Amblem2.webp" alt="Glocal logo" />
      </div>
      <h2 class="invite__title">
        Invite your friends, family, and/or colleagues
      </h2>
      <!-- <p class="invite__description">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
        Vestibulum consectetur, mauris vitae.
      </p> -->
      <form class="invite__form">
        <p>Email address</p>
        <input
          v-for="(field, index) in fields"
          :key="index"
          v-model="emails[index]"
          ref="myvalue"
          type="email"
          placeholder="Enter email"
        />
        <p class="error" v-if="error">{{ error }}</p>
        <button type="button" @click="addMoreField" class="add-more">
          <i class="icon icon-add_plus"></i>Add more
        </button>
      </form>
      <button @click="sendInvitation" class="invite__btn">
        <img src="@/assets/icons/send-invite.svg" alt="icon" />Send invitation
        <div v-if="loading" class="loader">
          <the-loader></the-loader>
        </div>
      </button>
      <div class="invite__link">
        <p class="invite__link--link" v-if="successMsg">{{ successMsg }}</p>
        <router-link class="invite__link--link" v-if="!successMsg" to="/discover">
          Invite later
        </router-link>
        <!-- <br>
        <br> -->
      </div>
    </div>
    <div v-if="!isAuthenticatedUser" class="login-message">
      <h2>Please login to continue</h2>
      <a-button @click="showLoginModal">Login</a-button>
    </div>
  </div>
  <the-login
    :visible="signInVisible"
    :visibleSignIn="signInVisible"
    @cancel="signInVisible = false"
    :footer="null"
  ></the-login>
  <notification-drawer
    :drawer-visibility="showNotificationDrawer"
    @close-drawer="showNotificationDrawer = false"
    :loading="loading"
  ></notification-drawer>
</template>
<script>
import TheHeader from "../../components/layout/ProfilePageHeader.vue";
import AccountsDropdown from "../../components/BaseComponents/AccountsDropdown.vue";
import TheLogin from "../../components/Auth/AccessModal.vue";
import TheLoader from "../BaseComponents/TheLoader.vue";
import NotificationDrawer from "../PdbComponents/Notifications.vue";

export default {
  data() {
    return {
      fields: 3,
      emails: [],
      signInVisible: false,
      error: false,
      successMsg: "",
      loading: false,
      showNotificationDrawer: false,
      regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    };
  },
  components: {
    TheHeader,
    AccountsDropdown,
    TheLogin,
    TheLoader,
    NotificationDrawer,
  },
  computed: {
    isAuthenticatedUser() {
      // console.log(this.$store.getters["auth/isAuthenticated"]);
      return this.$store.getters["auth/isAuthenticated"];
    },
    isUserRequest() {
      return this.$store.getters["profile/getUserRequestCount"];
    },
    isNotifications() {
      return this.$store.getters["notification/newNotificationCount"];
    },
    userId() {
      return this.$store.getters["auth/getUserId"];
    },
    displayPicture(){
      return this.$store.getters["profile/userInformation"].profile.profile_picture;
    },
  },
  methods: {
    addMoreField() {
      this.fields++;
    },
    async sendInvitation() {
      this.error = false;
      if (this.emails == "") {
        this.error = "Please enter atleast one email to invite";
        return false;
      }
      this.emails.forEach((email) => {
        if (email && !email.match(this.regex)) {
        this.error = 'Invalid email format';
        return;
      }
      })
      const payload = []
      this.emails.filter((val) => {
        if (typeof val !== 'undefined' && val !== null && val !== ""){
          payload.push({ email : val })
        }
      });
      try {
        this.loading = true;
        if(!this.error){
        const response = await this.$store.dispatch(
          "auth/inviteOnGlocal",
          payload
        );
        if (response.status === 200) {
          this.successMsg = "Invitation Sent Successfully";
          setTimeout(() => {
            this.$router.push("/discover");
          }, 3000);
        }
      }
      } catch (err) {
        err.response.data.forEach((val) => {
          this.error = val;
        })
      }
       this.loading = false;
    },
    showLoginModal() {
      this.signInVisible = true;
    },
    showNotifications() {
      this.getFeatures();
      this.showNotificationDrawer = true;
    },
    async getFeatures() {
      try {
        const response = await this.$store.dispatch(
          "article/getGlocalFeatures"
        );
        if (response.status === 200) {
          // this.loader = false;
        }
      } catch (err) {
        console.log(err);
      }
    },
    redirectToTabs(val) {
      if (val === 'network' || val === 'portfolio') {
        this.$router.push({
          path: `/user/${this.userId}/`,
          query: { tab: val },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.invite-container {
  height: 100vh;
  width: 100%;
  .header {
    background-color: $color-black !important;
    &__right-navigation {
      display: flex;
      align-items: center;
      z-index: 2;
      width: 100%;
      .ant-dropdown-link {
        height: 3rem;
        width: fit-content;
      }
      .icon-account_circle {
        padding-top: 0.2rem;
      }
      .navigation-icon {
        color: $color-white;
        margin-left: 3.1rem;
        width: 2.4rem;
        cursor: pointer;
        font-size: 3rem;
        @include respond(phone-large) {
          margin-left: 2rem;
        }
      }
      .notification {
        position: relative;
        margin-left: 3rem;
        width: 1.9rem;
        @include respond(phone-large) {
          margin-left: 2rem;
        }
        .bell-icon {
          width: 1.9rem;
          margin-left: 0;
        }
        .notifications-alert {
          position: absolute;
          content: "";
          width: 0.8rem;
          height: 0.8rem;
          background-color: red;
          border-radius: 100%;
          top: 0.2rem;
          right: 0.2rem;
        }
      }
      .icon-account {
        color: $color-black !important;
        border-radius: 100%;
        border: 0.2rem solid $color-black;
        object-fit: cover;
        font-size: 3rem;
        width: 5.2rem;
        height: 5.2rem;
        background-color: #ebebeb;
      }
      .weather-icon {
        display: flex;
        align-items: center;
        position: relative;
        color: $color-black;
        margin-right: 2.4rem;
        border-right: 0.22rem solid $color-white;
        border-left: 0.22rem solid $color-white;
        padding: 0 1rem;
        @include respond(phone-x-small) {
          display: none;
        }

        &__icon {
          color: $color-white;
          font-size: 2.5rem;
          font-family: $font-secondary-bold;
          position: relative;
          margin-right: 0.7rem;
          margin-left: 0;
        }
        &__para {
          color: $color-white;
          font-size: 1.2rem;
          font-family: $font-primary-bold;
          font-weight: 400;
          margin: 0;
        }
      }
      .btn-white {
        background-color: $color-white;
        color: #191919;
        height: 3.6rem;
        border: none;
        outline: none;
        padding: 1rem 5rem;
        font-family: $font-secondary;
        font-size: 1.44rem;
        line-height: 1;
        margin-left: 3.1rem;

        @include respond(laptop-small) {
          padding: 1rem 3rem;
        }

        @include respond(tab-port) {
          display: none;
        }
      }
      .login-btn {
        color: white;
        font-family: $font-primary;
        font-size: 1.6rem;
        background-color: $color-primary;
        width: 9rem;
        height: 4rem;
        border: none;
        outline: none;
        span {
          line-height: 0;
        }
      }
    }
  }
  .invite {
    min-width: 40%;
    margin: 10rem 2rem 0;
    padding-bottom: 4rem;
    &__icon {
      width: 5rem;
      height: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #688bff2b;
      margin: 0 auto 2rem;
      img {
        width: 50%;
      }
    }

    &__title {
      font-family: $font-primary-bold;
      font-size: 3rem;
      text-align: center;
      line-height: 4rem;
    }
    &__description {
      font-family: $font-primary;
      font-size: 1.6rem;
      text-align: center;
      line-height: 2.5rem;
      opacity: 0.7;
    }
    &__form {
      width: 32rem;
      margin: 6rem auto 0;
      input {
        width: 100%;
        height: 4rem;
        border: 1px solid #24242433;
        margin-bottom: 1.1rem;
        padding: 1.6rem;
        outline-color: $color-primary;
        font-size: 1.6rem;
        font-family: $font-primary;
        &::placeholder {
          opacity: 0.7;
        }
      }
      p {
        font-family: $font-primary-bold;
        font-size: 1.6rem;
        text-align: left;
        margin-bottom: 1rem;
      }
      .error {
        color: red;
        font-family: $font-primary;
        font-size: 1.6rem;
        width: 100%;
        line-height: 2rem;
      }
      .add-more {
        border: 1px solid #24242433;
        width: 100%;
        width: 100%;
        height: 4rem;
        background-color: transparent;
        color: gray;
        cursor: pointer;
        outline: none;
        font-family: $font-secondary-bold;
        font-size: 1.6rem;
        margin: 2rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease;
        i {
          font-size: 1.3rem;
          margin-right: 0.5rem;
        }
        &:hover {
          border-color: $color-primary;
          color: $color-primary;
        }
      }
    }
    &__btn {
      background-color: $color-primary;
      color: $color-white;
      display: flex;
      align-items: center;
      height: 5rem;
      justify-content: center;
      width: fit-content;
      margin: 5rem auto 0;
      font-family: $font-secondary-bold;
      font-size: 1.6rem;
      border: none;
      padding: 0 4rem;
      position: relative;
      cursor: pointer;
      img {
        width: 20px;
        margin-right: 1rem;
      }
      .loader {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        left: 0;
        background-color: rgba(255, 255, 255, 0.459);
        .ant-spin {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: initial;
          .anticon-loading {
            height: fit-content;
            .anticon-spin {
              width: 2.5rem;
              height: 2.5rem;
              color: $color-white;
            }
          }
        }
      }
    }
    &__link {
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 10rem;
      &--link {
        font-family: $font-primary-bold;
        font-size: 1.6rem;
        opacity: 0.7;
        color: $color-primary;
        transition: all 0.2s ease;
        &:hover {
          color: $color-primary;
        }
      }
    }
  }
  .login-message {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    h2 {
      font-family: $font-primary;
      font-size: 3rem;
      opacity: 0.7;
      margin-bottom: 2rem;
    }
    .ant-btn {
      background-color: $color-primary;
      color: $color-white;
      display: flex;
      align-items: center;
      height: 4.5rem;
      width: 14rem;
      justify-content: center;
      font-family: $font-secondary-bold;
      font-size: 1.6rem;
      border: none;
      img {
        width: 20px;
        margin-right: 1rem;
      }
    }
  }
}
</style>
